import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BigNumber, ethers } from 'ethers'

import { Button } from 'components/shared'
import { ModalRenameIndex, ModalWithdrawDETH } from 'components/app/Modals'

import { BlockswapSDKContext } from 'context/BlockswapSDKContext'
import { ValidatorT } from 'types'
import { isValidatorDETHWithdrawable } from 'utils/validators'

import { ReactComponent as PenIcon } from 'assets/images/pen.svg'
import { ReactComponent as DETHIcon } from 'assets/images/icon-deth.svg'
import { ReactComponent as SAVETHIcon } from 'assets/images/icon-saveth.svg'
import { ReactComponent as CheckGreyIcon } from 'assets/images/icon-check-grey.svg'
import { ReactComponent as ArrowTopRightGreenIcon } from 'assets/images/icon-arrow-top-right-green.svg'
import './styles.scss'
import IndexChart from './IndexChart'
import { mainnet, useNetwork } from 'wagmi'
import { API_ENDPOINT, NETWORK } from '../../../constants'

interface IProps {
  knotData: any
  globalSavIndexes: any
  isLoading: boolean
  data: ValidatorT[]
  onRefresh: () => void
}

const TableSavETH: FC<IProps> = ({ data, knotData, globalSavIndexes, onRefresh }) => {
  const { chain: activeChain } = useNetwork()
  const { sdk } = useContext(BlockswapSDKContext)
  const [isWithdrawDETHModalOpen, setIsWithdrawDETHModalOpen] = useState(false)
  const [openEditNameDlg, setOpenEditNameDlg] = useState(false)
  const [totalSavETH, setTotalSavETH] = useState('0')
  const [indexName, setIndexName] = useState('')
  const [myIndex, setMyIndex] = useState('0')
  const [allIndexAPRAverage, setAllIndexAPRAverage] = useState('0')
  const [liveExchangeRate, setLiveExchangeRate] = useState('--')

  useEffect(() => {
    const fetchData = async () => {
      if (!knotData || !knotData.indexId) return

      try {
        const myHeaders = new Headers()
        myHeaders.append('accept', 'application/json')

        const requestOptions = {
          method: 'GET',
          headers: myHeaders
        }

        const result = await fetch(
          `${
            API_ENDPOINT[activeChain?.id === mainnet.id ? NETWORK.MAINNET : NETWORK.TESTNET]
          }/indexAPRAverage?index=${knotData.indexId}`,
          requestOptions as any
        )
        const data = await result.json()

        if (!data.error) {
          setMyIndex(Number(data.indexAPR).toLocaleString(undefined, { maximumFractionDigits: 2 }))
          setAllIndexAPRAverage(
            Number(data.allIndexAPRAverage).toLocaleString(undefined, { maximumFractionDigits: 2 })
          )
          const liveExchangeRate =
            24 * knotData.numberOfKnots +
            parseFloat(ethers.utils.formatEther(BigNumber.from(data.totaldETHEarned))) /
              (24 * knotData.numberOfKnots)

          const totalEth = 24 * knotData.numberOfKnots
          const totalEarned = totalEth + data.totaldETHEarned / Math.pow(10, 9)
          setLiveExchangeRate(
            (totalEarned / totalEth).toLocaleString(undefined, { maximumFractionDigits: 3 })
          )
        }
      } catch (err) {
        console.log('indexAPRAverage err: ', err)
      }
    }

    fetchData()
  }, [knotData])

  const handleOpenNameEditDlg = () => {
    setOpenEditNameDlg(true)
  }

  const handleWithdrawAll = () => {
    setIsWithdrawDETHModalOpen(true)
  }

  const fetchIndexName = useCallback(async () => {
    if (sdk && knotData && knotData.indexId) {
      try {
        const savETHIndexNamingRegistryInstance = (
          await sdk.contractInstance
        ).savETHIndexNamingRegistry()
        const indexName = await savETHIndexNamingRegistryInstance.name(knotData.indexId)
        setIndexName(indexName)
      } catch (err) {
        console.log('fetchIndexName err: ', err)
      }
    }
  }, [sdk, knotData])

  useEffect(() => {
    fetchIndexName()
  }, [fetchIndexName])

  useEffect(() => {
    const fetchSavETH = async () => {
      if (sdk && knotData) {
        const result = await sdk.utils.dETHToSavETH(knotData.dETHTotal)
        setTotalSavETH(
          Number(ethers.utils.formatEther(BigNumber.from(result))).toLocaleString(undefined, {
            maximumFractionDigits: 2
          })
        )
      }
    }

    fetchSavETH()
  }, [sdk, knotData])

  const handleRefresh = () => {
    onRefresh()
    fetchIndexName()
  }

  if (!knotData) {
    return <div className="table__empty">No index found</div>
  }

  const totalRewards = knotData?.dETHTotalRewards
    ? Number(ethers.utils.formatEther(BigNumber.from(knotData.dETHTotalRewards))).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 4
        }
      )
    : 0
  const totalDETH = knotData?.dETHTotal
    ? Number(ethers.utils.formatEther(BigNumber.from(knotData.dETHTotal))).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2
        }
      )
    : 0

  const contractExchangeRate = knotData.dETHTotal
    ? parseFloat(ethers.utils.formatEther(BigNumber.from(knotData.dETHTotal))) /
      (24 * knotData.numberOfKnots)
    : 0

  const isWithdrawable = useMemo(() => {
    if (data && knotData) {
      const withdrawableValidators = data.filter((v) =>
        isValidatorDETHWithdrawable(v, knotData, true)
      )
      return !!withdrawableValidators.length
    }

    return false
  }, [data, knotData])

  return (
    <div className="saveth">
      <div className="saveth__header">
        <h3>{indexName || 'My Personal Index'}</h3>
        <button onClick={handleOpenNameEditDlg}>
          <PenIcon width={12} height={12} />
        </button>
      </div>
      <IndexChart indexId={knotData.indexId} />
      <div className="saveth__content">
        <div className="saveth__card">
          <div className="saveth__card--total-rewards">
            <p>Total Rewards Earned</p>
            <h2>{totalRewards} ETH</h2>
          </div>
          <div className="saveth__info">
            <p className="saveth__info__title">My Assets</p>
            <div className="saveth__info__field">
              <div>
                <DETHIcon /> total dETH
              </div>
              <p>{totalDETH}</p>
            </div>
            <div className="saveth__info__field">
              <div>
                <SAVETHIcon /> total savETH
              </div>
              <p>{totalSavETH}</p>
            </div>
          </div>
          <div className="saveth__info">
            <p className="saveth__info__title">
              Index Yield Rates<sup>*</sup>
            </p>
            <div className="saveth__info__field">
              <div>My Index</div>
              <p className={Number(myIndex) >= 0 ? 'text-primary700' : ''}>{myIndex} %</p>
            </div>
            <div className="saveth__info__field">
              <div>All Indexes</div>
              <p className={Number(allIndexAPRAverage) >= 0 ? 'text-primary700' : 'text-yellow'}>
                {allIndexAPRAverage} %
              </p>
            </div>
            <div className="saveth__info__desc">
              <p>
                <sup>*</sup> Rates are estimated in APR
              </p>
            </div>
          </div>
        </div>
        <div className="saveth__detail-card">
          <div>
            <p className="saveth__detail-card__title">Details</p>
            <p className="saveth__detail-card__label">
              <CheckGreyIcon /> Index ID: {knotData.indexId}
            </p>
            <p className="saveth__detail-card__label">
              <CheckGreyIcon /> Total number of KNOTs: {knotData.numberOfKnots}
            </p>
            <p className="saveth__detail-card__label">
              <CheckGreyIcon /> Contract exchange rate:{' '}
              {contractExchangeRate.toLocaleString(undefined, { maximumFractionDigits: 3 })}
            </p>
            <p className="saveth__detail-card__label">
              <CheckGreyIcon /> Live exchange rate: {liveExchangeRate}
            </p>
          </div>
        </div>
      </div>
      <Button variant="primary" disabled={!isWithdrawable} onClick={handleWithdrawAll}>
        Withdraw dETH to savETH index
      </Button>
      <a
        className="saveth__detail-card__link flex justify-center"
        href="https://help.joinstakehouse.com/en/articles/6288938-what-is-my-saveth-tab"
        target={'_blank'}
        rel="noopener noreferrer">
        <span>Learn More</span>
        <ArrowTopRightGreenIcon />
      </a>
      <ModalWithdrawDETH
        open={isWithdrawDETHModalOpen}
        fromIndex
        onClose={() => setIsWithdrawDETHModalOpen(false)}
      />
      <ModalRenameIndex
        open={openEditNameDlg}
        indexId={knotData.indexId}
        onClose={() => {
          setOpenEditNameDlg(false)
          handleRefresh()
        }}
      />
    </div>
  )
}

export default TableSavETH
